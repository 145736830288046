import Workflow from './base';

import { DomuiError, DynamicData } from '@domui-domain/type';

class AUTHORIZER extends Workflow {
  constructor() {
    super('authorizerwf');
  }

  async getIAMAccessLevels(requestor: string, data: DynamicData) {
    this.setAction('getIAMAccessLevels');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchEditPermission(requestor: string, data: DynamicData) {
    this.setAction('fetchEditPermission');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchClubPermission(requestor: string, data: DynamicData) {
    this.setAction('fetchClubPermission');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchDistrictPermission(requestor: string, data: DynamicData) {
    this.setAction('fetchDistrictPermission');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchOperationsAccess(
    clubId: string,
    userData?: DynamicData,
    individualId?: string
  ) {
    this.setAction('fetchOperationsAccess');
    this.setData({
      userData: userData || {},
      postData: {
        clubId,
        individualInfo: userData?.individualInfo || {},
        userData: userData || {},
        individualId: individualId || '',
      },
      individualId: individualId || '',
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async SSOToken(data: DynamicData) {
    this.setAction('sSOToken');
    this.setData({
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchConferenceList(requestor: string, data: DynamicData) {
    this.setAction('fetchConferenceList');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchConferenceFeedback(requestor: string, data: DynamicData) {
    this.setAction('fetchConferenceFeedback');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async getBasicInfo(onlineId: string) {
    this.setAction('getBasicInfo');
    this.setData({
      onlineId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }
}

export default AUTHORIZER;
