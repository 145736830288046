import React from 'react';
import Select, { SelectOptions, SelectProps } from '../Select';
import { useTranslation } from '@external/react-i18next';

type SuffixSelectProps = Omit<SelectProps, 'options'>;

const SuffixSelect: React.FC<SuffixSelectProps> = props => {
  const { t } = useTranslation();

  const suffixes = [
    'Jr.',
    'Sr.',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'Filho',
    'Hija',
    'Hijo',
    'Neto',
    'Padre',
    'Sobrinho',
  ];

  const suffixSelectOptions: SelectOptions = suffixes.map(suffix => ({
    label: t('global.suffix.{{suffix}}', '{{suffix}}', {
      suffix: suffix.toLowerCase(),
    }),
    value: suffix,
  }));

  return <Select options={suffixSelectOptions} {...props} />;
};

export default SuffixSelect;
