import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from '@external/react-i18next';
import TextField from '@components/Formik/TextField';
import Select from '@components/Formik/Select';

interface DateOfBirthProps {
  isYouthFlag?: boolean;
  isDayRequired: boolean;
  isMonthRequired: boolean;
}

const DateOfBirth: React.FC<DateOfBirthProps> = ({
  isYouthFlag,
  isDayRequired,
  isMonthRequired,
}) => {
  const { t } = useTranslation();

  const monthsOptions = moment.months().map(month => ({
    label: month,
    value: moment()
      .month(month)
      .format('MM'),
  }));

  return (
    <div className="birthdate-container">
      <p className={classNames('text-small font-bold block')}>
        {t('edit-personal-details.form.birthdate-label', 'Birthdate')}
      </p>
      <div className="flex space-x-4">
        <TextField
          name="dayOfBirth"
          label={t('edit-personal-details.form.day-of-birth-label', 'Day')}
          placeholder="DD"
          required={isDayRequired}
        />
        <Select
          name="monthOfBirth"
          label={t('edit-personal-details.form.month-of-birth-label', 'Month')}
          clearable
          options={monthsOptions}
          required={isMonthRequired}
        />
        <TextField
          name="yearOfBirth"
          label={t('edit-personal-details.form.year-of-birth-label', 'Year')}
          placeholder="YYYY"
          required
        />
      </div>
      {isYouthFlag && (
        <p
          id={`youth-protection-alert`}
          className={classNames('text-xs mt-2 mb-0, text-red-600')}
        >
          {t(
            'edit-personal-details.form.youth-protection-alert',
            'This is a youth protection alert, since the date of birth is calculated under 18.'
          )}
        </p>
      )}
      <p
        id={`yearOfBirth-description`}
        className={classNames('text-xs mt-2 mb-0, text-bright-blue-500')}
      >
        {t(
          'edit-personal-details.form.year-of-birth-info',
          'Year of birth is only visible to club officers.'
        )}
      </p>
    </div>
  );
};

export default DateOfBirth;
