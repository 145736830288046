import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Individual from '@domui-utils/workflow/individual';

type updateProfileArgs = {
  dominoId: string;
  requestBody: DynamicData;
};

// Custom hook to handle API calls
export const useUpdateProfilePhoto = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(null);
  const profilePhoto = async ({ dominoId, requestBody }: updateProfileArgs) => {
    setLoading(true);
    setError(null); // Clear error before new request
    try {
      const wfService = new Individual();
      await wfService.updateProfilePhoto(dominoId, requestBody);
    } catch (err) {
      setError(err as DomuiError);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, profilePhoto };
};
