import React from 'react';

import DateOfBirth from '@components/Formik/DateOfBirth';
import PersonalNameForm from '@components/Formik/PersonalNameForm';

import { getYouthFlag } from '../utils';

import { PersonalDetailsFormValues } from '@domain/profile';

import GenderInputs from '@domui-components/Formik/GenderInputs';

interface FormProps {
  values: PersonalDetailsFormValues;
}

const PersonalDetailsForm: React.FC<FormProps> = ({ values }) => {
  const { yearOfBirth, monthOfBirth, dayOfBirth } = values;

  return (
    <div className="desktop:flex-2 desktop:mr-24">
      <div className="max-w-lg">
        <PersonalNameForm />
        <GenderInputs id="N/A" />
        <DateOfBirth
          isYouthFlag={getYouthFlag(yearOfBirth)}
          isDayRequired={Boolean(monthOfBirth)}
          isMonthRequired={Boolean(dayOfBirth)}
        />
      </div>
    </div>
  );
};

export default PersonalDetailsForm;
