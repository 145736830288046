import React from 'react';

import { EditProfileSharingPermission } from '@domain/profile/types';

import NotificationIcon from '@assets/icons/circles/notification.svg';
import { useSharingPermissionsData } from '@domui-repositories/profile/index';

import { useTranslation } from '@external/react-i18next';

export const SharingPermissionsAlerts: React.FC<{
  selectedSharingPermission?: EditProfileSharingPermission;
  isClubOrDistrictOfficer?: boolean;
}> = ({ selectedSharingPermission, isClubOrDistrictOfficer }) => {
  const { t } = useTranslation();
  const { data } = useSharingPermissionsData();

  type sharingPermissionsType = { id: string; value: string };

  const isNoSharingPermissionSelected = ((data?.sharingPermissions as unknown) as sharingPermissionsType[])?.some(
    ({ value, id }) =>
      value === 'No Sharing' && id === selectedSharingPermission?.id
  );

  return (
    <div>
      {isClubOrDistrictOfficer && isNoSharingPermissionSelected && (
        <div className="grid grid-cols-10 gap-2">
          <span className="h-5 w-5 mx-2.5 mt-1" data-testid="alertIcon">
            <NotificationIcon />
          </span>
          <p className="text-sm max-w-sm col-span-9" data-testid="alertText">
            {t(
              'profile-edit.form.sharing-permission-alert-text',
              `As a club officer, during your term your email address(es) 
              will be shared with your club members, regardless of your privacy sharing settings.`
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default SharingPermissionsAlerts;
