import React, { createRef, useState } from 'react';

import { useField } from 'formik';

import { Avatar } from '@components/Avatar';
import { Button } from '@components/Button';
import Divider from '@components/Divider';

import { useErrorHandling } from '@use-cases/notifications';

import { acceptedImageTypes, maxImageSize } from '@utils/fixed-values';

import SharingPermissionSelect from '@domui-components/Formik/Select/SharingPermissionSelect';

import { useTranslation } from '@external/react-i18next';

interface Props {
  photoUri: string | null | undefined;
  showSharingPermissionSelect: boolean;
}

const maxImageSizeHumanReadable = `${maxImageSize / 1024 / 1024}MB`;

const ProfilePhoto: React.FC<Props> = ({
  photoUri,
  showSharingPermissionSelect,
}) => {
  const { t } = useTranslation();
  const [, , { setValue }] = useField<File | false | null>({
    name: 'photoUpload',
  });

  const [previewUrl, setPreviewUrl] = useState<string | null | undefined>(
    photoUri
  );
  const [fileSizeExceeded, setFileSizeExceeded] = useState<boolean>(false);

  useErrorHandling(
    t('profile_edit.limit_exceeded', 'The image is larger than {{max_size}}.', {
      max_size: maxImageSizeHumanReadable,
    }),
    fileSizeExceeded,
    'file-size.error'
  );

  const updatePhotoUrl = (newFile: File | false | null) => {
    if (newFile) {
      setPreviewUrl(URL.createObjectURL(newFile));
    } else {
      setPreviewUrl(null);
    }
    setValue(newFile);
  };

  const fileInput = createRef<HTMLInputElement>();
  const openBrowser = () => fileInput?.current?.click();

  const onFileChange = ({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (validity.valid && files && files.length > 0) {
      const file = files[0];
      // Validity only checks the format. We need to check the size
      // ourselves.
      if (file.size > maxImageSize) {
        setFileSizeExceeded(true);
      } else {
        setFileSizeExceeded(false);
        updatePhotoUrl(file);
      }
    }
  };

  return (
    <div>
      <h2 className="mt-10 desktop:mt-24">
        {t('user_profile.profile_photo', 'Profile Photo')}
      </h2>
      <div className="desktop:flex desktop:flex-row-reverse">
        <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg">
          {showSharingPermissionSelect && (
            <SharingPermissionSelect selectName="sharingPermissionsExtended.photo.id" />
          )}
        </div>
        <div className="desktop:flex-2 desktop:mr-24">
          <div className="desktop:flex desktop:items-center max-w-lg">
            <div className="flex justify-center tablet:justify-start mb-4 desktop:mr-6">
              <Avatar size="medium" photoUri={previewUrl || undefined} />
            </div>
            <div>
              <p className="mb-0">
                <label htmlFor="photoUpload" className="h4">
                  {t('user_profile.upload_image', 'Upload Image')}
                </label>
              </p>
              <p className="text-xs leading-xs">
                {t(
                  'user_profile.profile_photo',
                  'Choose an image from your files. For best results, use an image that is at least 500px wide.'
                )}
              </p>
              {previewUrl ? (
                <>
                  <Button
                    className="mr-4"
                    clickHandler={openBrowser}
                    secondary
                    small
                  >
                    {t('user_profile.profile_photo.change_button', 'Change')}
                  </Button>
                  <Button
                    className="mt-6 tablet:mt-0"
                    clickHandler={() => updatePhotoUrl(false)}
                    text
                    small
                  >
                    {t('user_profile.profile_photo.delete_button', 'Delete')}
                  </Button>
                </>
              ) : (
                <Button
                  className="mr-4"
                  clickHandler={openBrowser}
                  secondary
                  small
                >
                  {t('user_profile.profile_photo.add_button', 'Add')}
                </Button>
              )}
              <input
                type="file"
                className="sr-only"
                name="photoUpload"
                id="photoUpload"
                ref={fileInput}
                accept={acceptedImageTypes
                  .map(type => `image/${type}`)
                  .join(',')}
                onChange={onFileChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default ProfilePhoto;
