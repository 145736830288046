import React from 'react';
import { useTranslation } from '@external/react-i18next';
import SuffixSelect from '@components/Formik/Select/SuffixSelect';
import TextField from '@components/Formik/TextField';

interface Props {
  isFullNameRequired?: boolean;
}

const PersonalNameForm: React.FC<Props> = ({ isFullNameRequired }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        name="localizedName"
        label={t(
          'edit-personal-details.form.localized-name-label',
          'Full Name'
        )}
        description={t(
          'edit-personal-details.form.localized-name-info',
          'This field accepts non-Latin characters'
        )}
        required={isFullNameRequired}
      />
      <TextField
        name="prefix"
        label={t('edit-personal-details.form.prefix-label', 'Prefix')}
        description={t('edit-personal-details.form.prefix-label', 'Prefix')}
      />
      <TextField
        name="firstName"
        label={t('edit-personal-details.form.given-name-label', 'First Name')}
        required
        description={t(
          'edit-personal-details.form.given-name-info',
          'Latin characters required'
        )}
      />
      <TextField
        name="middleName"
        label={t('edit-personal-details.form.middle-name-label', 'Middle Name')}
        description={t(
          'edit-personal-details.form.middle-name-info',
          'Latin characters required'
        )}
      />
      <TextField
        name="lastName"
        label={t('edit-personal-details.form.family-name-label', 'Last Name')}
        required
        description={t(
          'edit-personal-details.form.last-name-info',
          'Latin characters required'
        )}
      />
      <SuffixSelect
        name="suffix"
        label={t('edit-personal-details.form.suffix-label', 'Suffix')}
        clearable
      />
    </>
  );
};

export default PersonalNameForm;
