import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Formik } from 'formik';

import { Button } from '@components/Button';
import Divider from '@components/Divider';
import {
  formatDateOfBirth,
  getDateOfBirth,
} from '@components/Formik/DateOfBirth/utils';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import PersonalDetailsForm from './PersonalDetailsForm';
import ProfilePhoto from './ProfilePhoto/ProfilePhoto';
import SharingPermissionMessage from './SharingPermissionMessage';
import { personalDetailsValidationSchema } from '../validationSchema';

import { PersonalDetailsFormValues } from '@domain/profile';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

// import { useDeletePhoto, useUploadPhoto } from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { Gender } from '@domui-components/Formik/GenderInputs/index';
import { useDeleteProfilePhoto } from '@domui-hooks/useDeleteProfilePhoto';
import { useFetchProfileDetails } from '@domui-hooks/useFetchProfileDetails';
import { useUpdateProfilePhoto } from '@domui-hooks/useUpdateProfilePhoto';
import { useUpdateProfileDetails } from 'src/domui/hooks/useUpdateProfileDetails';

import { useTranslation } from '@external/react-i18next';
// import { useProfilePhoto } from '@hooks/profile-photo';

interface Props extends RouteComponentProps {
  individualId: string;
}

const PersonalDetailsEdit: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();
  const { addSuccess, addError } = useNotifications();

  const {
    data: profileInfo,
    loading: fetching,
    error: fetchError,
    fetchProfileDetails,
  } = useFetchProfileDetails();
  useErrorHandling(fetchError?.message, !!fetchError, 'fetch.error');

  const {
    loading: updateing,
    error: updateError,
    updateProfileDetails,
  } = useUpdateProfileDetails();

  useErrorHandling(
    updateError?.description
      ? updateError.description
      : t('edit-personal-details.form.error', 'An error occurred.'),
    !!updateError,
    'edit-personal-details.form.error'
  );

  useEffect(() => {
    if (individualId) {
      fetchProfileDetails(individualId);
    }
  }, [individualId]);

  const canEditPhoto = true; // !!profileInfo?.personalDetails?.editPermissions?.photo;

  // const [
  //   // updatePersonalDetails,
  //   { loading: updating },
  // ] = useUpdatePersonalDetails();

  const { loading: uploading, profilePhoto } = useUpdateProfilePhoto();
  const {
    // loading: deleting,
    deletePhoto,
  } = useDeleteProfilePhoto();
  // const [uploadPhoto, { loading: uploading }] = useUploadPhoto();

  // const [deletePhoto, { loading: deleting }] = useDeletePhoto();

  // const { updateProfilePhoto } = useProfilePhoto();

  const handleFormSubmit = async (values: PersonalDetailsFormValues) => {
    const {
      suffix,
      dayOfBirth,
      monthOfBirth,
      yearOfBirth,
      // sharingPermissionsExtended,
      firstName,
      lastName,
      // localizedName,
      gender,
      // genderDescription,
      ...updatedValues
    } = values;

    try {
      if (individualId) {
        const { dateOfBirth, yearOfBirth: yob } = getDateOfBirth(
          dayOfBirth,
          monthOfBirth,
          yearOfBirth
        );

        const updateStatus = await updateProfileDetails({
          dominoId: individualId,
          data: {
            ...updatedValues,
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            dateOfBirth,
            yearOfBirth: `${yob}`,
            suffix: suffix ?? '',
            gender: !gender ? Gender.NotGiven : gender,
          },
        });
        if (updateStatus) {
          localizedNavigate(`/domui/profile/${individualId}`);
          addSuccess(
            t('edit-personal-details.form.success', 'Update successful.'),
            { id: 'form.success' }
          );
        }
      }

      if (canEditPhoto) {
        // Strictly check for false. Null is the default and tells that no change
        // was made.
        if (values.photoUpload === false) {
          await deletePhoto({ dominoId: individualId });
        } else if (values.photoUpload !== null) {
          // https://stackoverflow.com/a/52311051/580371
          const getBase64 = (file: File): Promise<string> => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                let encoded = reader.result
                  ?.toString()
                  .replace(/^data:(.*,)?/, '');
                if (encoded && encoded.length % 4 > 0) {
                  encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded || '');
              };
              reader.onerror = error => reject(error);
            });
          };

          const requestBody = {
            fileName: values.photoUpload.name,
            fileContent: await getBase64(values.photoUpload),
          };
          await profilePhoto({
            dominoId: individualId,
            requestBody,
          });
        }
      }
    } catch (error) {
      addError((error as Error).message, { id: 'form.error' });
    }
  };

  const handleCancel = () => {
    localizedNavigate(`/domui/profile/${individualId}`);
  };

  if (
    fetching ||
    uploading ||
    // deleting ||
    updateing ||
    !profileInfo?.personalDetails
  ) {
    return <Loading />;
  }
  // if (loading || uploading || deleting || !profileInfo?.personalDetails) {
  //   return <Loading />;
  // }

  const initialValues = {
    ...profileInfo.personalDetails,
    ...formatDateOfBirth(
      profileInfo.personalDetails.dateOfBirth,
      profileInfo.personalDetails.yearOfBirth
    ),
    photoUpload: null,
  };

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={personalDetailsValidationSchema(t)}
      >
        {({ values, isValid, handleSubmit }) => (
          <>
            <LinkPrevious
              path={`/domui/profile/${individualId}`}
              label={t('edit-profile.back-link', 'Profile')}
            />
            <Title>
              {t('edit-personal-details.title', 'Edit Personal Details')}
            </Title>
            {canEditPhoto && (
              <>
                <ProfilePhoto
                  photoUri={initialValues.photoUri}
                  showSharingPermissionSelect={
                    !!initialValues.sharingPermissionsExtended
                  }
                />
                <h2>
                  {t(
                    'edit-personal-details.subheader-personal-information',
                    'Personal Information'
                  )}
                </h2>
              </>
            )}
            <div className="desktop:flex desktop:flex-row-reverse">
              <SharingPermissionMessage />
              <PersonalDetailsForm values={values} />
            </div>
            <Divider />
            <div className="max-w-lg mb-20 mt-10">
              <Button
                full
                clickHandler={() => {
                  if (!isValid)
                    addError(
                      t(
                        'edit-personal-details.form.error-msg',
                        'Please correct the invalid fields'
                      ),
                      { id: 'form.error' }
                    );
                  handleSubmit();
                }}
              >
                {t('edit-personal-details.form.submit-label', 'Save Changes')}
              </Button>
              <Button className="mt-6" full text clickHandler={handleCancel}>
                {t('edit-personal-details.form.cancel-label', 'Cancel')}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </OneColumn>
  );
};

export default PersonalDetailsEdit;
