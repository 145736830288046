import React, { useEffect, useState } from 'react';

import { useField } from 'formik';
import { isEqual } from 'lodash';

import TextField from '@components/Formik/TextField';
import Loading from '@components/Loading';

import Select, { SelectProps } from '../Select';

import { DynamicData } from '@domui-domain/type';
import Metadata from '@domui-utils/workflow/metadata';

import { useTranslation } from '@external/react-i18next';

const generateOptions = (countryValue: string, states: DynamicData[]) =>
  states
    .filter(s => s?.countryCode === countryValue)
    .map(s => ({ label: s?.stateName, value: s?.code }));

type Props = Omit<SelectProps, 'options'> & {
  countryId: string;
  name?: string;
  textStateName?: string;
  textStateLabel?: string;
  hasStatesName?: string;
  countryValue: string;
};

const StateSelect: React.FC<Props> = ({
  countryId,
  countryValue,
  name = 'state',
  textStateName = 'address.internationalProvince',
  textStateLabel,
  hasStatesName,
  ...props
}) => {
  // We have this dummy field in the Formik fields for having
  // states available in side `Yup` scheme
  // we have validation which depends if we have received values from DIS or not
  const [field, , helpers] = useField(hasStatesName || '');

  const { t } = useTranslation();
  const [stateData, setStateData] = useState<DynamicData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // const { data, error } = useDISStates(countryId);

  useEffect(() => {
    async function fetchStateData() {
      try {
        const wfService = new Metadata();
        const response = await wfService.getStateData(countryId as string);
        setStateData(response);
        setLoading(false);
      } catch (error) {
        // Handle the error
        setStateData([]);
      } finally {
        setLoading(false); // Set loading to false when done
      }
    }
    fetchStateData();
  }, [countryId]);
  // useErrorHandling(error?.message, !!error, 'fetch-states.error');

  useEffect(() => {
    const hasStates = Boolean(stateData?.length);

    // If we received some states
    // and our `states` is not equal to states which are received then update
    if (!isEqual(field.value, hasStates)) {
      helpers.setValue(hasStates);
    }
  }, [stateData]);

  if (!stateData) {
    return <Loading inline small />;
  }
  if (loading) {
    return <Loading />;
  }

  if (!stateData.length) {
    return (
      <TextField name={textStateName} label={textStateLabel || props.label} />
    );
  }

  return (
    <Select
      searchable
      options={generateOptions(countryValue, stateData)}
      name={name}
      label={t('form.address.state-label', 'State/Province/Territory')}
      {...props}
    />
  );
};

export default StateSelect;
