import { useState } from 'react';

import { DomuiError } from '@domui-domain/type';
import Individual from '@domui-utils/workflow/individual';

type deleteProfileArgs = {
  dominoId: string;
};

// Custom hook to handle API calls
export const useDeleteProfilePhoto = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const deletePhoto = async ({ dominoId }: deleteProfileArgs) => {
    // Clear error before new request
    try {
      if (dominoId) {
        setLoading(true);
        setError(undefined);
        const wfService = new Individual();
        await wfService.deleteProfilePhoto(dominoId);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, deletePhoto };
};
